import Vue from 'vue';
import vueCustomElement from 'vue-custom-element';
import helperMixin from './lib/helper_mixin.js';

import OMS from './components/OMS.vue';


// Configure Vue to ignore the element name when defined outside of Vue.

/*Vue.config.ignoredElements=[
  'sams-crm-direct-leads',
  'sams-crm-converted-leads',
  'sams-crm-notperspective-leads',
];*/

// Enable the plugin
Vue.use(vueCustomElement);
Vue.mixin(helperMixin);

// Register your component
Vue.customElement('oms-app', OMS, {});
